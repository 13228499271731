import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@styled";
import { theme, darkTheme, creamyDarkTheme } from "@styled/theme";
import "@assets/fonts/font-awesome/font-awesome.css";
import "@assets/css/fonts.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { GlobalStyle } from "@assets/css/main-style";
import WhatsappButton from "@ui/whatsapp-button";
import Transition from "@components/transition";

const Layout = ({ children, location }) => {
    return (
        <ThemeProvider theme={creamyDarkTheme}>
            <Transition location={location}>
                <div className="wrapper">
                    <GlobalStyle />
                    {children}
                    <WhatsappButton />
                </div>
            </Transition>
        </ThemeProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({}),
};

export default Layout;
