import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import MapMarker from "@assets/images/icons/map-marker.png";
import { GooglMapWrap } from "./style";

const Marker = ({ text }) => (
    <div className="marker">
        <img src={MapMarker} alt={text} />
    </div>
);

Marker.propTypes = {
    text: PropTypes.string,
};

const GoogleMap = ({ center, APIKey, zoom, ...restProps }) => {
    return (
        <GooglMapWrap {...restProps}>
            <iframe
                title="google-map"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11983.53475347433!2d19.7911337!3d41.3331425!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031a8fa2b8139%3A0xea6956f2bf89df8b!2sDentisti%20in%20Albania%20%7C%20Turismo%20Dentale%20%7C%20Clinius%20Dental%20Center!5e0!3m2!1sen!2s!4v1728936014497!5m2!1sen!2s"
                width="1000"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </GooglMapWrap>
    );
};

GoogleMap.propTypes = {
    center: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
    APIKey: PropTypes.string,
    zoom: PropTypes.number,
};
GoogleMap.defaultProps = {
    center: {
        lat: 41.333161930076024,
        lng: 19.79113233291141,
    },
    APIKey: "AIzaSyD79MY72taVRlZVX2DU6L5PXOh3ezUUKMc",
    zoom: 15,
};

export default GoogleMap;
