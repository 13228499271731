exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-homepages-appointment-jsx": () => import("./../../../src/pages/homepages/appointment.jsx" /* webpackChunkName: "component---src-pages-homepages-appointment-jsx" */),
  "component---src-pages-homepages-homepage-jsx": () => import("./../../../src/pages/homepages/homepage.jsx" /* webpackChunkName: "component---src-pages-homepages-homepage-jsx" */),
  "component---src-pages-innerpages-about-us-jsx": () => import("./../../../src/pages/innerpages/about-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-about-us-jsx" */),
  "component---src-pages-innerpages-contact-us-jsx": () => import("./../../../src/pages/innerpages/contact-us.jsx" /* webpackChunkName: "component---src-pages-innerpages-contact-us-jsx" */),
  "component---src-pages-innerpages-dental-services-jsx": () => import("./../../../src/pages/innerpages/dental-services.jsx" /* webpackChunkName: "component---src-pages-innerpages-dental-services-jsx" */),
  "component---src-pages-innerpages-dental-technologies-jsx": () => import("./../../../src/pages/innerpages/dental-technologies.jsx" /* webpackChunkName: "component---src-pages-innerpages-dental-technologies-jsx" */),
  "component---src-pages-innerpages-dental-tourism-jsx": () => import("./../../../src/pages/innerpages/dental-tourism.jsx" /* webpackChunkName: "component---src-pages-innerpages-dental-tourism-jsx" */),
  "component---src-pages-innerpages-faq-jsx": () => import("./../../../src/pages/innerpages/faq.jsx" /* webpackChunkName: "component---src-pages-innerpages-faq-jsx" */),
  "component---src-pages-innerpages-google-map-jsx": () => import("./../../../src/pages/innerpages/google-map.jsx" /* webpackChunkName: "component---src-pages-innerpages-google-map-jsx" */),
  "component---src-pages-innerpages-staff-jsx": () => import("./../../../src/pages/innerpages/staff.jsx" /* webpackChunkName: "component---src-pages-innerpages-staff-jsx" */),
  "component---src-pages-innerpages-why-albania-jsx": () => import("./../../../src/pages/innerpages/why-albania.jsx" /* webpackChunkName: "component---src-pages-innerpages-why-albania-jsx" */),
  "component---src-templates-dental-service-index-jsx": () => import("./../../../src/templates/dental-service/index.jsx" /* webpackChunkName: "component---src-templates-dental-service-index-jsx" */)
}

