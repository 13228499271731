import React from "react";
import PropTypes from "prop-types";
import cn from "clsx";
import { useScrollTop } from "@hooks";
import { ScrollTopButton } from "./style";

const WhatsappButton = ({ className, ...props }) => {
    const onClickHandler = () => {
        window.open(
            "https://api.whatsapp.com/send/?phone=355692193113&text&type=phone_number&app_absent=0",
            "_blank"
        );
    };

    return (
        <ScrollTopButton
            type="button"
            className={cn(className, "whatsapp-button")}
            onClick={onClickHandler}
            isStick={true}
            {...props}
        >
            <i className="icon arrow-up fab fa-whatsapp"></i>
        </ScrollTopButton>
    );
};

WhatsappButton.propTypes = {
    className: PropTypes.string,
};

export default WhatsappButton;
