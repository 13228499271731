const translations = {
    en: {
        Search: "Search",
        ExploreAlbania: "Explore the Hidden Gem: Why Visit Albania?",
        GetStarted: "Get Started",
        Services: "Services",
        Staff: "Staff",
        Location: "Location",
        DentalTourism: "Dental Tourism",
        Contact_Us: "Contact Us",
        Describe: "Please describe your problem",
        Name: "Name",
        Email: "Email",
        Subject: "Subject",
        SendMessage : "Send Message",
        Touch : "We will contact you directly via the provided email",
        Book_appointment : "Book an appointment with one of our specialists",
        Contact_Us: "Contact Us",
        Where_to_Find_Us: "Where to find us",
        Appointment: "Make an appointment",
        Quick_links: "Quick Links",
        Our_Staff: "Our Staff",
        FAQS: "Frequently Asked Questions",
        About_Us: "About Us",
        Menu: "Menu",
        Support: "Support",
        Information: "Call us for more information",
        View_service: "View Services"
    },
    it: {
        Search: "Ricerca",
        ExploreAlbania: "Esplora il gioiello nascosto: Perché visitare l'Albania?",
        GetStarted: "Loslegen",
        Services: "Servizi",
        Staff: "Staff",
        Location: "Location",
        DentalTourism: "Turizmi Dentar",
        Contact_Us: "Contact Us",
        Describe: "Scrivi il tuo problema, per favore",
        Name: "Nome",
        Email: "Email",
        Subject: "Oggetto",
        SendMessage : "Invia messaggio",
        Touch : "Ti contatteremo direttamente via email",
        Book_appointment : "Prenota un appuntamento con uno dei nostri specialisti",
        Contact_Us: "Contattaci",
        Where_to_Find_Us: "Dove trovarci",
        Appointment: "Prenota un appuntamento",
        Quick_links: "Collegamenti rapidi",
        Our_Staff: "Il nostro Staff",
        FAQS: "Domande frequenti",
        About_Us: "Chi siamo",
        Menu: "Menu",
        Support: "Supporto",
        Information: "Chiamaci per maggiori informazioni",
        View_service: "Guarda i servizi"
    },
    de: {
        Search: "Suche",
        ExploreAlbania: "Entdecke das verborgene Juwel: Warum Albanien besuchen?",
        GetStarted: "Loslegen",
        Services: "Dienstleistungen",
        Staff: "Mitarbeiter",
        Location: "Standort",
        DentalTourism: "Zahntourismus",
        Contact_Us: "Kontaktiere uns",
        Describe: "Bitte beschreiben Sie Ihr Problem",
        Name: "Name",
        Email: "E-Mail",
        Subject: "Betreff",
        SendMessage : "Nachricht senden",
        Touch : "Wir werden Sie direkt per E-Mail kontaktieren",
        Book_appointment : "Buchen Sie einen Termin mit einem unserer Spezialisten",
        Contact_Us: "Kontaktieren Sie uns",
        Where_to_Find_Us: "Wo Sie uns finden",
        Appointment: "Vereinbaren Sie einen Termin",
        Quick_links: "Schnellzugriff",
        Our_Staff: "Unser Team",
        FAQS: "Häufig gestellte Fragen",
        About_Us: "Über uns",
        Menu: "Menü",
        Support: "Unterstützung",
        Information: "Rufen Sie uns für weitere Informationen an",
        View_service: "Dienstleistungen anzeigen"
    },
    sq: {
        Search: "Kërko",
        ExploreAlbania: "Eksploro gurin e çmuar: Pse të vizitosh Shqipërinë?",
        GetStarted: "Fillo",
        Services: "Shërbimet",
        Staff: "Stafi",
        Location: "Vendndodhja",
        DentalTourism: "Turizmi Dentar",
        Contact_Us: "Na kontaktoni",
        Describe: "Ju lutem përshkruani problemin tuaj",
        Name: "Emri",
        Email: "Emaili",
        Subject: "Subjekti",
        SendMessage : "Dërgo mesazhin",
        Touch : "Ne do t'ju kontaktojmë drejtpërdrejt në emailin e shënuar",
        Book_appointment : "Rezervoni një takim me një nga specialistët",
        Contact_Us: "Na kontaktoni",
        Where_to_Find_Us: "Ku të na gjeni",
        Appointment: "Lini një takim",
        Quick_links: "Linqe të shpejta",
        Our_Staff: "Stafi ynë",
        FAQS: "Pyetjet më të shpeshta",
        About_Us: "Rreth Nesh",
        Menu: "Menuja",
        Support: "Suporti",
        Information: "Na telefononi për më shumë informacion",
        View_service: "Shikoni shërbimet"
    },
};

export default translations;
